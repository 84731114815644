.img-atr{
    width: 25% !important;
    
}

@media only screen and (max-width: 900px) {
    
    .mobile{
        width: 80% !important;
        position: absolute;
        margin-right: 6%;
        top: 40px;
        
    }
  }

  @media only screen and (max-width: 1250px) {


    .deletes{
        width: 56px !important;
    }



  }


  
